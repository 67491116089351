// Generated by Framer (b12dec4)

import { addFonts, cx, CycleVariantState, SVG, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["ZQFMxsjXk"];

const variantClassNames = {ZQFMxsjXk: "framer-v-52ry5z"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "ZQFMxsjXk", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-C553n", classNames)} style={{display: "contents"}}>
<Transition value={transition}><motion.div {...restProps} className={cx("framer-52ry5z", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"ZQFMxsjXk"} ref={ref} style={{...style}}><SVG className={"framer-1bnvpkt"} layout={"position"} layoutDependency={layoutDependency} layoutId={"FiVJ6euXI-shape"} opacity={1} svg={"<svg xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" viewBox=\"0 0 63 65\"><path d=\"M 37.27 27.1 L 60.583 0 L 55.058 0 L 34.816 23.53 L 18.648 0 L 0 0 L 24.449 35.582 L 0 64 L 5.525 64 L 26.902 39.151 L 43.976 64 L 62.624 64 L 37.268 27.1 Z M 29.703 35.895 L 27.226 32.352 L 7.515 4.159 L 16.001 4.159 L 31.907 26.912 L 34.385 30.455 L 55.061 60.03 L 46.575 60.03 L 29.703 35.897 Z\" fill=\"rgba(0,0,0,1)\"></path></svg>"} svgContentId={3949549778} withExternalLayout/></motion.div></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-C553n [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-C553n .framer-hq6e26 { display: block; }", ".framer-C553n .framer-52ry5z { height: 65px; overflow: hidden; position: relative; width: 63px; }", ".framer-C553n .framer-1bnvpkt { flex: none; height: 65px; left: calc(49.20634920634922% - 63px / 2); position: absolute; top: calc(49.230769230769255% - 65px / 2); width: 63px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 65
 * @framerIntrinsicWidth 63
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables false
 */
const FramerU8dToropL: React.ComponentType<Props> = withCSS(Component, css, "framer-C553n") as typeof Component;
export default FramerU8dToropL;

FramerU8dToropL.displayName = "Social Buttons / X";

FramerU8dToropL.defaultProps = {height: 65, width: 63};

addFonts(FramerU8dToropL, [])